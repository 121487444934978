/* App.css or your custom styles file */
.super-admin-panel {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 240px;
    background-color: #2c3e50; /* Dark background color */
    color: #ecf0f1; /* Light text color */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .app-name {
    font-size: 18px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  a {
    text-decoration: none;
    color: #ecf0f1; /* Light text color */
    font-size: 16px;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  /* Add your specific styles for UserList, PaymentHistory, and RolePermission components */
  
  /* Responsive styles for mobile */
  @media (max-width: 768px) {
    .sidebar {
      position: fixed;
      top: 0;
      left: -240px;
      height: 100%;
      transition: left 0.3s ease; /* Use left for the transition */
    }
  
    .sidebar-open {
      left: 0;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .menu-toggle-mobile {
      display: none;
    }
  
    .content {
      margin-left: 0;
    }
  }
  