/* src/css/UserList.css */
.user-list-container {
    padding: 20px;
  }
  
  .user-list {
    list-style: none;
    padding: 0;
  }
  
  .user-list li {
    margin-bottom: 10px;
  }
  
  .user-list li strong {
    color: #3498db; /* Blue color for user names */
  }
  


  /* UserList.css */
.toggle-button {
    color: white;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .toggle-button[data-is-admin="true"] {
    background-color: green;
  }
  
  .toggle-button[data-is-admin="false"] {
    background-color: red;
  }
  
  .redcolor{
    background-color: red;
  }

   .greencolor{
    background-color: green;
  }